<template>
  <div></div>
</template>

<script>
import { Loading, Message } from 'element-ui'
import { authLogin, loginUserInfo } from '@/api/user'

export default {
  name: 'AuthLogin',
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async doAuthLogin() {
      const loadingInstance = Loading.service({ fullscreen: true })
      console.log(this.$route.query)
      const { origin, auth } = this.$route.query
      if (origin && auth) {
        const userInfo = await authLogin({ origin, auth })
        // await loginUserInfo().then(resp => {
        //   window.localStorage.setItem('roles', resp.roles)
        //   sessionStorage.setItem('userInfo', JSON.stringify(resp))
        //   that.$store.commit('user/SET_INFO')
        // })
        if (userInfo.is_staff) {
          window.localStorage.setItem('roles', 'ADMIN|USER')
        } else {
          window.localStorage.setItem('roles', 'USER')
        }
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
        this.$store.commit('user/SET_INFO')
        window.localStorage.setItem('origin', window.document.referrer)
        loadingInstance.close()
        this.$router.push('/')
      } else {
        loadingInstance.close()
        Message.error('第三方登录参数缺失')
      }
    }
  },
  mounted() {
    this.doAuthLogin()
  }
}
</script>
